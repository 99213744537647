import * as React from "react";

import { GatsbyImage as Img } from "gatsby-plugin-image";

import { LinkList, SEO } from "@parataxic/shared-ui";

import { PopoverSiteLink } from "../components/Popover";

interface IBookItemProps {
  book: GatsbyTypes.MdxBook;
}

const BookItem: React.FC<IBookItemProps> = ({ book }) => (
  <div
    style={{
      boxShadow: "md",
      borderWidth: "1px",
      borderRadius: "md",
      padding: "1rem",
      width: "100%",
    }}
    role="article"
  >
    <h3>{book.title}</h3>
    {book?.coverImage?.childImageSharp?.gatsbyImageData && (
      <div style={{ textAlign: "center" }}>
        <Img
          image={book?.coverImage?.childImageSharp?.gatsbyImageData}
          alt={book.title}
          style={{ width: "30%", margin: "0 auto", minWidth: "270px" }}
        />
      </div>
    )}
    <div
      style={{
        gridTemplateColumns: "2fr 8fr", // 1fr 1fr 6fr 1fr 6fr 2fr 8fr
        gridColumn: "5/6",
        gridGap: 3,
        display: "grid",
        maxWidth: "1000px",
        padding: ".5rem",
        marginBottom: "1rem",
        textAlign: "center",
      }}
    >
      {book.authors && (
        <div>
          <LinkList links={book.authors} Component={PopoverSiteLink} />
        </div>
      )}
      {book.mentionedPsychologists && (
        <div>
          <LinkList
            links={book.mentionedPsychologists}
            Component={PopoverSiteLink}
          />
        </div>
      )}
      {book.mentionedTheories && (
        <div>
          <LinkList
            links={book.mentionedTheories}
            Component={PopoverSiteLink}
          />
        </div>
      )}
    </div>
  </div>
);
interface IProps {
  data: GatsbyTypes.BookMediaListQuery;
  pageContext: { book: GatsbyTypes.MdxBook };
}

const View: React.FC<IProps> = ({ pageContext: { book } }) => {
  const seoProps: { description?: string } = {};
  return (
    <>
      <>
        <SEO title={book.title} {...seoProps} />
        <div>
          <BookItem book={book} />
        </div>
      </>
    </>
  );
};

export default View;
